import { connect } from "react-redux";
import { getBrandDisplayName } from "app/reducers/partnerSelector";
import SocialBar from "./SocialBar";

const mapStateToProps = state => {
	return {
		resolution: state.resolution,
		partnerData: state.partnerData,
		brandDisplayName: getBrandDisplayName(state),
	};
};

export default connect(mapStateToProps)(SocialBar);
