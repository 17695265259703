import { useLayoutEffect, useRef, useState } from "react";
import { isServerSide } from "app/utils/utils";

export const INTERSECTION_STATUS = {
	LEAVING_FROM_BOTTOM: "LEAVING_FROM_BOTTOM",
	LEAVING_FROM_TOP: "LEAVING_FROM_TOP",
	ENTERING: "ENTERING",
};

/**
 * @param elementIntersect
 * @param options https://developer.mozilla.org/fr/docs/Web/API/Intersection_Observer_API#cr%C3%A9ation_d'un_observateur_d'intersection
 */
const useIntersectionObserver = (elementIntersect, once, options) => {
	const isIntersectionObserverSupported = !isServerSide && "IntersectionObserver" in window;

	const initialValue =
		isServerSide || isIntersectionObserverSupported ? undefined : INTERSECTION_STATUS.ENTERING;

	const [isIntersecting, updateIntersection] = useState(initialValue);

	const observer = useRef(null);

	let passed = false;

	useLayoutEffect(() => {
		//to check when element get's position sticky
		if (elementIntersect && "IntersectionObserver" in window) {
			observer.current = new IntersectionObserver(
				entries => {
					if (entries[0].isIntersecting && passed === false) {
						updateIntersection(INTERSECTION_STATUS.ENTERING);
						if (once) {
							passed = true;
						}
					} else if (
						!entries[0].isIntersecting &&
						entries[0].boundingClientRect.top < 0 &&
						!once
					) {
						updateIntersection(INTERSECTION_STATUS.LEAVING_FROM_TOP);
					} else if (
						!entries[0].isIntersecting &&
						entries[0].boundingClientRect.top > 0 &&
						!once
					) {
						updateIntersection(INTERSECTION_STATUS.LEAVING_FROM_BOTTOM);
					}
				},
				{
					...options,
					threshold: [0, 1],
				}
			);

			observer.current.observe(elementIntersect);
		}

		return () => {
			if (observer.current) {
				observer.current.disconnect();
			}
		};
	});

	return isIntersecting;
};

export default useIntersectionObserver;
