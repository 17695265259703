import * as React from "react";

function SvgIconShield(props) {
	return (
		<svg id="Calque_1" x={0} y={0} viewBox="0 0 14.4 16.5" xmlSpace="preserve" {...props}>
			<style>{".st0{fill:currentColor}"}</style>
			<path
				id="Trac\xE9_1449"
				d="M14.4,4.2L14.4,4.2c0-1-0.6-1.7-1.5-1.9c-0.2-0.1-0.5-0.1-0.7-0.2C12,2,11.7,1.9,11.4,1.8 L9.6,1.1C9.1,0.8,8.7,0.6,8.2,0.3C8,0.1,7.7,0,7.3,0c0,0-0.1,0-0.1,0S7.1,0,7.1,0C6.8,0,6.4,0.1,6.2,0.3C5.7,0.6,5.3,0.8,4.8,1.1 L3,1.8C2.7,1.9,2.5,2,2.2,2.1C2,2.1,1.7,2.2,1.5,2.3C0.6,2.4,0,3.2,0,4.1v5.8c0.2,1.3,0.9,2.5,2,3.3c1.3,1.2,2.7,2.2,4.2,3 c0.3,0.2,0.7,0.3,1,0.3c0,0,0,0,0.1,0s0,0,0.1,0c0.3,0,0.7-0.1,1-0.3c1.5-0.8,2.9-1.8,4.2-3c1.1-0.8,1.8-2,2-3.3V9.5l0,0L14.4,4.2 L14.4,4.2z M13,5.7L13,5.7v3.8c-0.2,1.1-0.8,2.2-1.8,2.9c-1.1,1-2.4,1.8-3.7,2.6c-0.2,0.1-0.5,0.1-0.7,0c-1.3-0.7-2.5-1.6-3.7-2.6 c-0.9-0.7-1.6-1.7-1.8-2.9V5.7l0,0V4.1c0-0.2,0.2-0.4,0.4-0.4C3,3.4,4.2,2.9,5.4,2.4l0,0l0,0l0,0c0.5-0.3,1-0.5,1.5-0.8l0,0 c0.1-0.1,0.3-0.1,0.5,0c0,0,0,0,0,0C8,1.8,8.5,2.1,9,2.4l0,0l0,0l0,0c1.1,0.6,2.3,1,3.6,1.3c0.2,0,0.4,0.2,0.4,0.4L13,5.7z"
				className="st0"
			/>
			<path
				id="Trac\xE9_1508"
				d="M8.5,7V5.2H5.9V7H4.2v2.6h1.7v1.7h2.6V9.5h1.7V7H8.5z"
				className="st0"
			/>
		</svg>
	);
}

export default SvgIconShield;
