import * as React from "react";

function SvgIconCovid(props) {
	return (
		<svg viewBox="0 0 23.1 17" {...props}>
			<path
				fill="currentColor"
				d="M6.5,9.8L6.5,9.8L6.5,9.8C6.2,9.7,6.1,9.4,6.1,9.2c0-0.1,0.1-0.3,0.2-0.3c0.1-0.1,0.3-0.1,0.4-0.1 c3.1,1,6.5,1,9.6,0c0.3-0.1,0.6,0.1,0.6,0.3c0,0.1,0,0.3,0,0.4s-0.2,0.2-0.3,0.3c-1.7,0.5-3.4,0.8-5.1,0.8 C9.8,10.6,8.1,10.3,6.5,9.8z M6.5,7.5L6.5,7.5L6.5,7.5c1.7,0.3,3.4,0.4,5,0.4c1.7,0,3.4-0.1,5-0.4c0.1,0,0.3-0.1,0.4-0.2 C17,7.2,17.1,7.1,17,6.9c0-0.3-0.3-0.5-0.6-0.5l0,0C13.2,7,9.9,7,6.7,6.5c-0.3,0-0.6,0.2-0.6,0.5C6.1,7.2,6.2,7.5,6.5,7.5z M6.3,12.4C6.3,12.4,6.4,12.4,6.3,12.4c1.7,0.8,3.5,1.2,5.2,1.2c1.8,0,3.5-0.4,5.2-1.2c0.3-0.1,0.4-0.4,0.3-0.7 c0-0.1-0.1-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.4,0c0,0,0,0-0.1,0c-3,1.5-6.4,1.5-9.4,0c-0.1-0.1-0.3-0.1-0.4-0.1 c-0.1,0-0.3,0.1-0.3,0.2C6,11.9,6.1,12.2,6.3,12.4z M23.1,5.5v1.9c-0.2,2.2-1.8,4-4,4.3c-1.1,3-3.8,5-6.9,5.3c-0.2,0-0.4,0-0.6,0 s-0.4,0-0.6,0c-3.2-0.3-5.8-2.4-6.9-5.3c-2.2-0.3-3.8-2.1-4-4.3l0-1.9c0-0.9,0.8-1.7,1.7-1.7h1.9C3.8,3.3,4.2,3,4.7,3h0.6 c1.4,0,2.7-0.6,3.5-1.7C9,1.1,9.1,1,9.3,0.8c1.5-1.2,3.8-1,5,0.5C15.2,2.4,16.5,3,17.8,3h0.6c0.5,0,1,0.3,1.2,0.8h1.9 c0.4,0,0.9,0.2,1.2,0.5C23,4.6,23.1,5,23.1,5.5z M19.7,8.5c0,0.7-0.1,1.3-0.2,2c1.4-0.4,2.5-1.6,2.6-3.1l0-1.9 c0-0.3-0.3-0.6-0.6-0.6h-1.8V8.5z M11.3,1.1C10.6,1.1,10,1.5,9.6,2C8.6,3.3,7,4.1,5.3,4.1H4.6c-0.1,0-0.2,0.1-0.2,0.2v0.2h1 c1.8,0,3.4-0.9,4.5-2.3c0.1-0.1,0.2-0.2,0.3-0.3c0.4-0.3,0.8-0.5,1.3-0.5c0.6,0,1.2,0.3,1.6,0.8c1.1,1.4,2.7,2.2,4.5,2.3h1V4.3 c0-0.1-0.1-0.2-0.2-0.2h-0.6c-1.7,0-3.3-0.8-4.3-2.1c-0.1-0.1-0.2-0.3-0.4-0.4C12.6,1.2,12,1,11.3,1.1z M4.5,8.5 c0,0.8,0.1,1.7,0.4,2.5c0.9,2.7,3.3,4.6,6.1,4.9c0.4,0,0.7,0,1.1,0h0c2.8-0.3,5.2-2.2,6.1-4.9c0.3-0.8,0.4-1.6,0.4-2.5V5.6h-1 c-2.1,0-4-1-5.3-2.7c0-0.1-0.1-0.1-0.1-0.1c-0.2-0.2-0.5-0.2-0.7-0.2c-0.3,0-0.5,0.2-0.7,0.4C9.5,4.6,7.6,5.6,5.5,5.6h-1V8.5z M3.4,8.5V4.9H1.7c-0.3,0-0.6,0.3-0.6,0.6v1.9c0.1,1.5,1.2,2.7,2.6,3.1C3.5,9.9,3.4,9.2,3.4,8.5z M21.5,4L21.5,4L21.5,4L21.5,4z"
			/>
		</svg>
	);
}

export default SvgIconCovid;
