import React from "react";
import "./whatsapp-share-button.scss";
import PropTypes from "prop-types";
import IconWhatsapp from "app/pages/.shared/static/icons/IconWhatsapp";

class WhatsAppShareButton extends React.PureComponent {
	constructor() {
		super();
		this.onShare = this.onShare.bind(this);
	}

	onShare() {
		if (typeof this.props.onClick === "function") {
			this.props.onClick();
		}
	}

	render() {
		const { text, label } = this.props;

		return (
			<a
				className="whatsapps-share-button"
				href={`whatsapp://send?text=${text}`}
				onClick={this.onShare}
			>
				<span className="whatsapps-share-button__icon">
					<IconWhatsapp height={20} width={20} />
				</span>
				<span className="whatsapps-share-button__label">{label}</span>
			</a>
		);
	}
}

WhatsAppShareButton.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	onClick: PropTypes.func,
};

export default WhatsAppShareButton;
