import React from "react";
import "./twitter-share-button.scss";
import PropTypes from "prop-types";
import IconTwitter from "app/pages/.shared/static/icons/IconTwitter";

class TwitterShareButton extends React.PureComponent {
	constructor() {
		super();
		this.onShare = this.onShare.bind(this);
	}

	onShare() {
		if (typeof this.props.onClick === "function") {
			this.props.onClick();
		}
	}

	render() {
		const { text, label } = this.props;

		return (
			<a
				className="twitter-share-button"
				rel="noopener noreferrer"
				target="_blank"
				href={`https://twitter.com/intent/tweet?text=${text}`}
				onClick={this.onShare}
			>
				<span className="twitter-share-button__icon">
					<IconTwitter height={20} width={20} />
				</span>
				<span className="twitter-share-button__label">{label}</span>
			</a>
		);
	}
}

TwitterShareButton.propTypes = {
	// Attention le & doit être encodé si present (à remplacer par %26)
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	onClick: PropTypes.func,
};

export default TwitterShareButton;
