import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import WhatsAppShareButton from "app/pages/.shared/social/whatapps/WhatsAppShareButton";
import MessengerShareLink from "app/pages/.shared/social/facebook/MessengerShareLink";
import CopyToClipboardButton from "app/pages/.shared/CopyToClipboardButton/CopyToClipboardButton";
import MailToButton from "app/pages/.shared/MailTo/MailToButton";
import { sendTagOnSocialShare } from "app/utils/analytics";
import { resolutionPropType } from "app/utils/propTypes";
import { RESOLUTION } from "app/constants";
import { stringify } from "qs";
import "./SocialBar.scss";

const SocialBar = ({ urlToShare, resolution, partnerData = {}, brandDisplayName }) => {
	const intl = useIntl();

	const onCopySuccess = useCallback(() => {
		sendTagOnSocialShare("clipboard");
	}, []);

	const onMailShare = useCallback(() => {
		sendTagOnSocialShare("email");
	}, []);

	const onWhatsAppShare = useCallback(() => {
		sendTagOnSocialShare("whatsapp");
	}, []);

	const onMessengerShare = useCallback(() => {
		sendTagOnSocialShare("messenger");
	}, []);

	const partnerDataQuery = stringify(partnerData);

	const emailSubject = intl.formatMessage({ id: "share.email.subject" });
	const emailBody = intl.formatMessage(
		{ id: "share.email.body" },
		{
			url: `${urlToShare}?source=share_email%26${partnerDataQuery}`,
			brandName: brandDisplayName,
		}
	);

	return (
		<div className="social-bar">
			<div className="social-bar__title">
				<FormattedMessage id="share.title.label" />
			</div>
			<div className="social-bar__body">
				{(resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM) && (
					<div className="social-bar__item">
						<WhatsAppShareButton
							onClick={onWhatsAppShare}
							label={<FormattedMessage id="share.whatsapp.button.label" />}
							text={`${urlToShare}?source=share_whatsapp%26${partnerDataQuery}`}
						/>
					</div>
				)}
				<div className="social-bar__item">
					<MessengerShareLink
						resolution={resolution}
						onClick={onMessengerShare}
						label={<FormattedMessage id="share.messenger.button.label" />}
						link={`${urlToShare}?source=share_messenger&${partnerDataQuery}`}
					/>
				</div>
				<div className="social-bar__item">
					<MailToButton
						subject={emailSubject}
						onClick={onMailShare}
						label={<FormattedMessage id="share.email.button.label" />}
						body={emailBody}
					/>
				</div>
				<div className="social-bar__item">
					<CopyToClipboardButton
						onCopySuccess={onCopySuccess}
						label={<FormattedMessage id="share.clipboard.button.label" />}
						text={`${urlToShare}?source=share_copylink&${partnerDataQuery}`}
					/>
				</div>
			</div>
		</div>
	);
};

SocialBar.propTypes = {
	resolution: resolutionPropType,
	urlToShare: PropTypes.string.isRequired,
	partnerData: PropTypes.object,
	brandDisplayName: PropTypes.string,
};

export default React.memo(SocialBar);
