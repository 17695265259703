import React from "react";

const IconCrossDark = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="10.707"
		height="10.706"
		viewBox="0 0 10.707 10.706"
	>
		<path
			fill="none"
			stroke="currentColor"
			d="M5 5l-5 5 5-5-5-5 5 5 5-5-5 5 5 5z"
			transform="translate(.354 .354)"
		/>
	</svg>
);

export default IconCrossDark;
