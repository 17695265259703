import React from "react";

const SvgIconPhoneFull = props => (
	<svg viewBox="0 0 348.08 347.29" {...props}>
		<path
			fill="currentcolor"
			d="M340.27,275.08l-53.76-53.76c-10.71-10.66-28.44-10.34-39.52.74l-27.08,27.08-5.34-3c-17.1-9.48-40.51-22.46-65.14-47.11s-37.7-48.14-47.21-65.26c-1-1.81-2-3.56-2.91-5.22l18.18-18.15,8.94-8.95c11.1-11.1,11.4-28.83.72-39.52L73.39,8.19C62.71-2.49,45-2.16,33.87,8.94L18.72,24.17l.41.41a87.61,87.61,0,0,0-12.48,22A91.29,91.29,0,0,0,1.1,68.94C-6,127.79,20.89,181.56,93.87,254.54,194.74,355.41,276,347.79,279.54,347.42a90.83,90.83,0,0,0,22.4-5.63,87.31,87.31,0,0,0,21.94-12.43l.33.29,15.35-15C350.63,303.53,351,285.8,340.27,275.08Z"
			transform="translate(0 -.39)"
		/>
	</svg>
);

export default SvgIconPhoneFull;
