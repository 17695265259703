import React from "react";

const IconPaiement2x = props => (
	<svg viewBox="0 0 201.15 142.61" {...props}>
		<path
			fill="currentColor"
			d="M221.33 110.28c.42 0 .93.6 1.21 1 1.85 2.9 3.65 5.83 5.47 8.75.25.4.51.79.89 1.37.4-.62.72-1.08 1-1.55 1.82-2.92 3.62-5.85 5.48-8.74a1.85 1.85 0 0 1 1.16-.88c2.81-.08 5.62 0 8.7 0-.38.67-.61 1.12-.89 1.54-3.2 4.88-6.37 9.76-9.62 14.6a1.92 1.92 0 0 0 0 2.53c3.59 5.3 7.11 10.65 10.65 16 .28.42.55.86 1 1.52-3.28 0-6.27 0-9.26 0-.41 0-.9-.62-1.17-1.06-2.29-3.75-4.54-7.52-6.8-11.29-.14-.24-.32-.46-.59-.85-.37.57-.68 1-1 1.49-2.24 3.61-4.46 7.24-6.74 10.83a2 2 0 0 1-1.28.9c-2.86.08-5.73 0-8.92 0 .4-.67.65-1.13.95-1.58 3.61-5.46 7.2-10.93 10.88-16.35a2.26 2.26 0 0 0 0-3c-3.07-4.5-6-9.07-9-13.61-.28-.43-.55-.88-1-1.63C215.62 110.23 218.48 110.19 221.33 110.28zM67.44 207.55a2.71 2.71 0 0 1 .32.62 9.55 9.55 0 0 0 8 8 2.7 2.7 0 0 1 .62.31h-8.9z"
			transform="translate(-67.44 -73.84)"
		/>
		<path
			fill="currentColor"
			d="M212.85,176.35a46.85,46.85,0,1,0-46.76-46.87A46.92,46.92,0,0,0,212.85,176.35ZM78.23,203.82c0,1.46.39,1.84,1.84,1.84q71.14-.06,142.28,0c1.4,0,1.79-.42,1.78-1.78-.06-6.07,0-12.14,0-18.22,0-.51-.07-1-.12-1.66-.45,0-.64.06-.82.09a53,53,0,0,1-20.22,0c-17.79-3.54-30.93-13.43-39.46-29.42a1.93,1.93,0,0,0-2-1.2q-40.81,0-81.61,0c-1.38,0-1.65.46-1.65,1.72Q78.29,179.51,78.23,203.82Zm0-71.52c0,1.15.71,1,1.43,1q38.23,0,76.47,0c1.2,0,1.39-.42,1.4-1.5,0-3,.09-5.92.33-8.86.18-2.22.63-4.41,1-6.69H78.43a2.71,2.71,0,0,0-.14.52C78.28,122,78.29,127.14,78.25,132.3ZM164,103.38c8.79-15.84,22.09-25.48,40-28.64,2.2-.39,4.43-.61,6.64-.9h5.16a8,8,0,0,0,1.36.33A49.75,49.75,0,0,1,235,78.55c18.08,8.33,29.07,22.26,32.69,41.9.38,2.05.61,4.13.91,6.19v5.74a12.07,12.07,0,0,0-.33,1.36c-.24,1.89-.3,3.82-.68,5.68-3.72,18.33-13.93,31.76-30.67,40.12a3.41,3.41,0,0,0-2.26,3.66c.11,7.89.08,15.78,0,23.67a9.62,9.62,0,0,1-7.33,9.16c-.5.13-1,.29-1.49.43H76.33a2.7,2.7,0,0,0-.62-.31,9.55,9.55,0,0,1-8-8,2.71,2.71,0,0,0-.32-.62V114.29c.18-.59.37-1.17.54-1.76,1.34-4.53,5-7.08,10.22-7.08q41.08,0,82.17,0A3.53,3.53,0,0,0,164,103.38Z"
			transform="translate(-67.44 -73.84)"
		/>
		<path
			fill="currentColor"
			d="M178.8 36.3L167.2 54l12.6 19h-10.1l-6.6-10.9-1.4-2.5-1.5 2.6L153.4 73h-10.1l13.1-19.7-11.2-17h9.8l5.5 8.7 1.5 2.6 1.6-2.7 5.4-8.6H178.8zM110 43.9c4.3-6.6 8.8-8.6 14.8-8.6 6.9 0 12.8 4.3 12.8 11.8 0 7.1-4.2 11.3-8.3 15.9l-1.8 2h11.6v8H110v-1.1l2.1-2.5c10.6-12.5 15.8-16.8 15.8-21.8 0-2.8-1.7-4.3-4.4-4.3-4.3 0-6.4 4-7.2 5.6L110 43.9z"
		/>
	</svg>
);

export default IconPaiement2x;
