import React from "react";

const IconInsuranceChecked = props => (
	<svg viewBox="0 0 27.815 25" {...props}>
		<path
			fill="currentColor"
			d="M8.81 70.151A2.072 2.072 0 0 1 7 69.094L.177 56.942A1.376 1.376 0 0 1 .7 55.069l1.48-.832a1.376 1.376 0 0 1 1.67.25l4.936 7.084c3.145-5.485 10.36-13.21 17.083-16.3a1.375 1.375 0 0 1 1.841.735 1.375 1.375 0 0 1-.6 1.715c-6.376 3.643-13.956 16.419-16.464 21.3a2.072 2.072 0 0 1-1.8 1.124z"
			transform="translate(0 -45.151)"
		/>
	</svg>
);

export default IconInsuranceChecked;
