import React from "react";

const SvgIconHome1 = props => (
	<svg viewBox="0 0 19.5 19" {...props}>
		<path
			fill="none"
			fillRule="evenodd"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M9.75,1.11l6.64,6.64V17.89H12.07V14.24a1.33,1.33,0,0,0-1.33-1.33h-2a1.33,1.33,0,0,0-1.33,1.33v3.65H3.11V7.75L9.75,1.11"
		/>
	</svg>
);

export default SvgIconHome1;
