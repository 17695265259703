import React, { useCallback } from "react";
import { connect } from "react-redux";
import env from "app/utils/env";
import { RESOLUTION } from "app/constants";
import { resolutionPropType } from "app/utils/propTypes";
import PropTypes from "prop-types";
import IconMessenger from "app/pages/.shared/static/icons/IconMessenger";
import waitForFacebookApi from "app/utils/waitForFacebookApi";
import "./messenger-share-link.scss";

/**
 * Sur dekstop : on envoie un message Facebook https://developers.facebook.com/docs/sharing/reference/send-dialog
 * sur mobile : on ouvre l'application Messenger https://developers.facebook.com/docs/sharing/messenger
 */
const MessengerShareLink = ({ link, resolution, label, onClick }) => {
	const shareOnMessenger = useCallback(() => {
		if (window?.FB?.ui) {
			window.FB.ui(
				{
					method: "send",
					display: "dialog",
					link: link,
				},
				() => {
					if (typeof onClick === "function") {
						onClick();
					}
				}
			);
		}
	}, [link]);

	const FACEBOOK_APP_ID = env("FACEBOOK_APP_ID");

	const shareElement =
		resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM ? (
			<a
				className="messenger-share-link"
				href={`fb-messenger://share/?link=${link}&app_id=${FACEBOOK_APP_ID}`}
			>
				<span className="messenger-share-link__icon">
					<IconMessenger height={20} width={20} />
				</span>
				<span className="messenger-share-link__label">{label}</span>
			</a>
		) : (
			<a className="messenger-share-link" onClick={shareOnMessenger}>
				<span className="messenger-share-link__icon">
					<IconMessenger height={20} width={20} />
				</span>
				<span className="messenger-share-link__label">{label}</span>
			</a>
		);

	return shareElement;
};

MessengerShareLink.propTypes = {
	link: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	onClick: PropTypes.func,
	resolution: resolutionPropType,
};

const mapStateToProps = state => {
	return {
		resolution: state.resolution,
	};
};

export default connect(mapStateToProps)(waitForFacebookApi(MessengerShareLink));
